import axios from 'axios';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getSelfInternalUserDetailUrl } from '../../helpers/EnvironmentHelper';
import { useDispatch } from 'react-redux';
import { populateSelfUserData } from '../../State_Management/Redux/SelfUserDatareducers.ts';

const OAuthSuccessInternal = () => {
    const navigate = useNavigate();
    const [params] = useSearchParams();

    const dispatch = useDispatch();

    const [hasRun, setHasRun] = useState(false);
    const [token, setToken] = useState(localStorage.getItem('Authorization'));
    
    useEffect(() => {
        
        const retrievedToken = params.get('token');
        if(localStorage.getItem('Authorization') && retrievedToken){
            localStorage.removeItem('Authorization');
            setToken(null);

        }

        if(retrievedToken && !localStorage.getItem('Authorization')){
            localStorage.setItem('Authorization', retrievedToken);
           setToken(retrievedToken);  
     
        }
        window.history.replaceState(null, '', window.location.pathname);

        if (localStorage.getItem('Authorization')) {   
            // populate user details related reducer
            
            getSelfUserData();
            navigate('/internal/home');
        } else {
            alert('OAuth login failed');
            navigate('/login');
        }
    }, [params, token]);

    const getSelfUserData = async () => {
        try {
            const { status, data } = await axios.get(getSelfInternalUserDetailUrl(), {
                headers: { 'Authorization': `Bearer ${token}` }
            })
            console.log(status);
            if (status !== 200) {
                navigate("/login");
            }
            dispatch(populateSelfUserData(data));
            console.log("user data : " , data);
        }
        catch (ex) {
            console.error(ex);
            navigate("/login");
        }
    }

    return <h1>Processing OAuth login...</h1>;
};

export default OAuthSuccessInternal;
