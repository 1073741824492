import proj4 from 'proj4';
const { useCallback } = require("react");



export function formatWithIntl(timestamp) {
  if (!timestamp) return null
  const date = new Date(timestamp);

  const formatter = new Intl.DateTimeFormat(navigator.language, {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });

  return formatter.format(date);
}

export function formatWithIntlNoTime(timestamp) {
  if (!timestamp) return null
  const date = new Date(timestamp);

  const formatter = new Intl.DateTimeFormat(navigator.language, {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });

  return formatter.format(date);
}

export function reversePointCoordinate(point) {
  if (point) {
    return [point[1], point[0]];
  }
  else return null;
}

export function reverseMultiLineStringCoordinates(multiLineString) {
  var returnMultiLineString = [];
  if (multiLineString) {

    for (let line of multiLineString) {
      let lineCoord = []
      for (let point of line) {
        lineCoord.push([point[1], point[0]]);
      }
      returnMultiLineString.push(lineCoord);
    }
    return returnMultiLineString;
  }
  else return null;
}


export function reverseLineStringCoordinates(lineString) {
  var returnLineString = [];
  if (lineString) {

    for (let point of lineString) {
      returnLineString.push([point[1], point[0]]);
    }

    return returnLineString;
  }
  else return null;
}

export function reverseGeometryCoordinates(geometry) {
  var returnGeometry;
  if (geometry == undefined || geometry == null) return null;
  if (geometry) {
    if (geometry.type === "MultiLineString") {
      returnGeometry = reverseMultiLineStringCoordinates(geometry.coordinates);
    }
    else if (geometry.type === "LineString") {
      returnGeometry = reverseLineStringCoordinates(geometry.coordinates);
    }
    else if (geometry.type === "Point") {
      returnGeometry = reversePointCoordinate(geometry.coordinates);
    }
    return returnGeometry;
  }
  else {
    return null;
  }
}

export function isEmptyObject(obj) { return Object.keys(obj).length === 0 };


export function formatAmount(currencyFormat /*e.g GBP */, amount) {
  return new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  }).format(amount);
}

export function getArrayFromMedian(median, numberOfEntities, maxNum) {
  const array = [];
  let start = Math.min(Math.max(1, median - 2), Math.max(1, maxNum - numberOfEntities + 1));
  for (let i = start; i < start + numberOfEntities; i++) {
    if (i <= maxNum) array.push(i);
  }
  return array;
}

export function convertPointTo4326(geometryPoint) {

  if (geometryPoint == null || geometryPoint == undefined) return null;
  // Define the source and destination EPSG codes
  const sourceEPSG = geometryPoint?.crs?.properties?.name; // WGS84 (latitude, longitude)
  const destEPSG = 'EPSG:4326';   // Web Mercator (X, Y in meters)

  // Define a point in the source CRS (WGS84)
  const point = geometryPoint?.coordinates; // Longitude, Latitude for the Colosseum in Rome

  // Perform the transformation
  const transformedPoint = proj4(sourceEPSG, destEPSG, point);

  geometryPoint.coordinates = transformedPoint;
  geometryPoint.crs.properties.name = destEPSG;

  console.log('Transformed Point:', transformedPoint);
  return geometryPoint;

}

export function removeDuplicatesInArray(array2D) {
  const uniqueArray = [];
  const seen = new Set();
  if(array2D == null) return null

  for (const item of array2D) {
    const stringified = JSON.stringify(item); // Convert array to string for comparison
    if (!seen.has(stringified)) {
      seen.add(stringified);
      uniqueArray.push(item); // Preserve order by pushing in sequence
    }
  }

  return uniqueArray;
}

// module.exports = {
//   formatWithIntl, reversePointCoordinate, reverseMultiLineStringCoordinates,
//   reverseLineStringCoordinates, reverseGeometryCoordinates,
//   isEmptyObject, formatAmount, formatWithIntlNoTime, getArrayFromMedian, convertPointTo4326
// };


