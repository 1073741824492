// // components/Admin.tsx
// import React, { Suspense, lazy } from 'react';
// import { Route, Routes, Link, useParams } from 'react-router-dom';

// // Lazy load the nested components
// const Dashboard = lazy(() => import('../components/Admin/dashboard.tsx'));

// const Client: React.FC = () => {
// //   const { path } = useParams<{ path: string }>();

//   return (
//     <div>
//       {/* <h2>Admin Module</h2>
//       <ul>
//         <li><Link to={`${path}/dashboard`}>Dashboard</Link></li>
//         <li><Link to={`${path}/users`}>Users</Link></li>
//         <li><Link to={`${path}/settings`}>Settings</Link></li>
//       </ul> */}
//       <Suspense fallback={<div>Loading...</div>}>
//         <Routes>
//           {/* <Route path="/" element={<div>Select an option</div>} /> */}
//           <Route path="dashboard" element={<Dashboard />} />
//           {/* <Route path="users" element={<Users />} />
//           <Route path="settings" element={<Settings />} /> */}
//         </Routes>
//       </Suspense>
//     </div>
//   );
// };

// export default Client;

// src/components/Client/ClientRoutes.tsx
import { Disclosure, DisclosureButton, Menu, MenuButton, MenuItems, MenuItem, DisclosurePanel } from '@headlessui/react';
import { Bars3Icon, XMarkIcon, BellIcon } from '@heroicons/react/24/outline';
import React, { Suspense, lazy, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import NewQuote from './NewQuote.tsx';
import { getJobSubmitUrl } from '../../helpers/EnvironmentHelper.js';
import JobSummaryPage from './JobSummary/JobSummaryPage.tsx';

const Home = lazy(() => import('./PlannerDashboard.tsx'));
const JobsList = lazy(() => import('./JobsList/JobsListPage.tsx'));
// const Profile = lazy(() => import('./Profile'));
const navigation = [
  { name: 'Dashboard', href: 'home', current: true },
  { name: 'Jobs', href: 'jobsList', current: false },
  { name: 'Projects', href: '#', current: false },
  { name: 'Calendar', href: '#', current: false },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}
const InternalRoutes: React.FC = () => {

  const [isNewQuoteOpen, setIsNewQuoteOpen] = useState(false);

  let authToken = localStorage.getItem("Authorization");

  // Function to open the modal
  const openNewQuote = () => setIsNewQuoteOpen(true);

  // Function to close the modal
  const closeNewQuote = () => setIsNewQuoteOpen(false);

  return (
    <main className="flex flex-col h-screen">
      <section>
        <Disclosure as="nav" className="bg-gray-800">
          <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
            <div className="relative flex h-16 items-center justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <DisclosureButton className="group relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  <Bars3Icon aria-hidden="true" className="block h-6 w-6 group-data-[open]:hidden" />
                  <XMarkIcon aria-hidden="true" className="hidden h-6 w-6 group-data-[open]:block" />
                </DisclosureButton>
              </div>
              <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex flex-shrink-0 items-center">
                  <img
                    alt="Your Company"
                    src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500"
                    className="h-8 w-auto"
                  />
                </div>
                <div className="hidden sm:ml-6 sm:block">
                  <div className="flex space-x-4">
                    {navigation.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        aria-current={item.current ? 'page' : undefined}
                        className={classNames(
                          item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                          'rounded-md px-3 py-2 text-sm font-medium',
                        )}
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>
                </div>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                <button
                  type="button"
                  className="relative rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                >
                  <span className="absolute -inset-1.5" />
                  <span className="sr-only">View notifications</span>
                  <BellIcon aria-hidden="true" className="h-6 w-6" />
                </button>

                {/* Profile dropdown */}
                <Menu as="div" className="relative ml-3">
                  <div>
                    <MenuButton className="relative flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                      <span className="absolute -inset-1.5" />
                      <span className="sr-only">Open user menu</span>
                      <img
                        alt=""
                        src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                        className="h-8 w-8 rounded-full"
                      />
                    </MenuButton>
                  </div>
                  <MenuItems
                    transition
                    className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                  >
                    <MenuItem>
                      <a href="#" className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100">
                        Your Profile
                      </a>
                    </MenuItem>
                    <MenuItem>
                      <a href="#" className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100">
                        Settings
                      </a>
                    </MenuItem>
                    <MenuItem>
                      <a href="#" className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100">
                        Sign out
                      </a>
                    </MenuItem>
                  </MenuItems>
                </Menu>
              </div>
            </div>
          </div>

          <DisclosurePanel className="sm:hidden">
            <div className="space-y-1 px-2 pb-3 pt-2">
              {navigation.map((item) => (
                <DisclosureButton
                  key={item.name}
                  as="a"
                  href={item.href}
                  aria-current={item.current ? 'page' : undefined}
                  className={classNames(
                    item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                    'block rounded-md px-3 py-2 text-base font-medium',
                  )}
                >
                  {item.name}
                </DisclosureButton>
              ))}
            </div>
          </DisclosurePanel>
        </Disclosure>
      </section>
      <section className="h-5/6 flex-grow">
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="home" element={<Home />} />
            <Route path="jobsList" element={<JobsList />} />
            <Route path="jobSummary" element={<JobSummaryPage />} />
            {/* <Route path="profile" element={<Profile />} /> */}
          </Routes>
        </Suspense>
      </section>
    </main>

  );
}

export default InternalRoutes;

