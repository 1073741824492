// counterSlice.ts
import { createSlice } from '@reduxjs/toolkit';



const initialState: any = {
  selfUserDetail:{
 
  },

};



export const selfUserDetail = createSlice({
  name: 'selfUserDetail',
  initialState,
  reducers: {
    populateSelfUserData: (state,payloadAction:any) => {
      state.selfUserDetail=payloadAction.payload;
    },
    // jobCardSelected: (state, data: any) => {
    //   state.jobCardList = data.payload;
    // } 
  },
});

export const { populateSelfUserData, } = selfUserDetail.actions;
export default selfUserDetail.reducer;
