// src/store/actions/actionTypes.ts

import { useDispatch } from "react-redux";
import { getInternalAllLineSegmentsDetailsUrl, getInternalJobListSummaryTableUrl, getInternalJobSummaryDetailsUrl, getInternalJobSummaryOptionDetailsUrl, getInternalJobSummaryOptionsTableUrl, getInternalLineDetailsUrl, getInternalUpdateLineRouteUrl } from "../../helpers/EnvironmentHelper";
import { jobListSummaryAction } from "./jobListSummary.ts";
import { jobSummaryDetailsAction } from "./jobSummaryDetailsReducer.ts";



export const updateJobListSearchField = (field, value, dispatch) => {
  dispatch(jobListSummaryAction({
    type: 'UPDATE_SEARCH_FIELD',
    payload: { field, value },
  }));
};

export const fetchJobListSearchResults = async (searchCriteria: any, dispatch: any) => {
  try {
    let authToken = localStorage.getItem("Authorization");
    fetch(getInternalJobListSummaryTableUrl(), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authToken}`
      },
      body: JSON.stringify(searchCriteria)
    })
      .then((response) => response.json())
      .then((result) => {
        console.log('Success:', result);
        dispatch(jobListSummaryAction({ type: 'SET_SEARCH_RESULTS', payload: result }));
      })
      .catch((error) => {
        console.error('Error:', error);
        dispatch(jobListSummaryAction({ type: 'SET_SEARCH_RESULTS', payload: {} }));
      });

  } catch (error) {
    dispatch(jobListSummaryAction({ type: 'SET_SEARCH_RESULTS', payload: {} }));
  }
};

export const updateJobOptionSearchField = (field, value, dispatch) => {
  dispatch(jobSummaryDetailsAction({
    type: 'UPDATE_JOBOPTION_SEARCH_FIELD',
    payload: { field, value },
  }));
  // The below line was added so that the JobSummaryDetails section clears down when the page is moved
  updateJobOptionIdInJobSummaryPage(null, dispatch);
  dispatch(jobSummaryDetailsAction({ type: 'SET_JOB_OPTION_DETAILS', payload: {} }));
};

export const updateJobIdInJobSummaryPage = (value, dispatch) => {
  dispatch(jobSummaryDetailsAction({
    type: 'SET_JOB_ID',
    payload: value,
  }));
};

export const updateJobOptionIdInJobSummaryPage = (value, dispatch) => {
  dispatch(jobSummaryDetailsAction({
    type: 'SET_JOB_OPTION_ID',
    payload: value,
  }));
  // The below line is required so that the edit flag is set as false
  updateJobRouteEditedInJobSummaryPage(false, dispatch);
};

export const updateJobRouteEditedInJobSummaryPage = (value, dispatch) => {
  dispatch(jobSummaryDetailsAction({
    type: 'SET_JOB_ROUTE_EDITED',
    payload: value,
  }));
};

export const updateJobRouteLatLngsEditedInJobSummaryPage = (value, dispatch) => {
  dispatch(jobSummaryDetailsAction({
    type: 'SET_JOB_ROUTE_LATLNGS_EDITED',
    payload: value,
  }));
};

export const updateJobCoordinateLatLngsEditedInJobSummaryPage = (value, dispatch) => {
  dispatch(jobSummaryDetailsAction({
    type: 'SET_JOB_COORDINATES_LATLNG_EDITED',
    payload: value,
  }));
};

export const fetchJobOptionSearchResults = async (searchCriteria: any, dispatch: any) => {
  try {
    let authToken = localStorage.getItem("Authorization");
    fetch(getInternalJobSummaryOptionsTableUrl(), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authToken}`
      },
      body: JSON.stringify(searchCriteria)
    })
      .then((response) => response.json())
      .then((result) => {
        console.log('Success:', result);
        dispatch(jobSummaryDetailsAction({ type: 'SET_JOB_OPTION_SEARCH_RESULTS', payload: result }));
      })
      .catch((error) => {
        console.error('Error:', error);
        dispatch(jobSummaryDetailsAction({ type: 'SET_JOB_OPTION_SEARCH_RESULTS', payload: {} }));
      });

  } catch (error) {
    dispatch(jobSummaryDetailsAction({ type: 'SET_JOB_OPTION_SEARCH_RESULTS', payload: {} }));
  }
};

export const fetchJobSummaryDetailResults = async (jobId, dispatch: any) => {
  try {
    let authToken = localStorage.getItem("Authorization");
    if (jobId == null || jobId == undefined) return;
    fetch(getInternalJobSummaryDetailsUrl() + "/" + jobId, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authToken}`
      },
    })
      .then((response) => response.json())
      .then((result) => {
        console.log('Success:', result);
        dispatch(jobSummaryDetailsAction({ type: 'SET_JOB_SUMMARY_SECTION', payload: result }));
      })
      .catch((error) => {
        console.error('Error:', error);
        dispatch(jobSummaryDetailsAction({ type: 'SET_JOB_SUMMARY_SECTION', payload: {} }));
      });

  } catch (error) {
    dispatch(jobSummaryDetailsAction({ type: 'SET_JOB_SUMMARY_SECTION', payload: {} }));
  }
};

export const fetchJobOptionDetails = async (optionId, dispatch: any) => {
  try {
    let authToken = localStorage.getItem("Authorization");
    if (optionId == null || optionId == undefined) {
      dispatch(jobSummaryDetailsAction({ type: 'SET_JOB_OPTION_DETAILS', payload: {} }));
      return;
    };
    fetch(getInternalJobSummaryOptionDetailsUrl() + "/" + optionId, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authToken}`
      },
    })
      .then((response) => response.json())
      .then((result) => {
        console.log('Success:', result);
        let lineId = result?.linesegmentId;
        dispatch(jobSummaryDetailsAction({ type: 'SET_LINESEGMENT', payload: lineId }));
        dispatch(jobSummaryDetailsAction({ type: 'SET_JOB_OPTION_DETAILS', payload: result }));
      })
      .catch((error) => {
        console.error('Error:', error);
        dispatch(jobSummaryDetailsAction({ type: 'SET_JOB_OPTION_DETAILS', payload: {} }));
      });

  } catch (error) {
    dispatch(jobSummaryDetailsAction({ type: 'SET_JOB_OPTION_DETAILS', payload: {} }));
  }
};

export const fetchLineSegmentDetails = async (lineId, dispatch: any) => {
  try {
    let authToken = localStorage.getItem("Authorization");
    if (lineId == null || lineId == undefined) return;
    fetch(getInternalLineDetailsUrl() + "/" + lineId, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authToken}`
      },
    })
      .then((response) => response.json())
      .then((result) => {
        console.log('Success:', result);
        dispatch(jobSummaryDetailsAction({ type: 'SET_LINESEGMENT_DETAILS', payload: result }));

      })
      .catch((error) => {
        console.error('Error:', error);
        dispatch(jobSummaryDetailsAction({ type: 'SET_LINESEGMENT_DETAILS', payload: {} }));
      });

  } catch (error) {
    dispatch(jobSummaryDetailsAction({ type: 'SET_LINESEGMENT_DETAILS', payload: {} }));
  }
};

export const fetchJobAllLineSegmentDetails = async (jobId, dispatch: any) => {
  try {
    let authToken = localStorage.getItem("Authorization");
    if (jobId == null || jobId == undefined) return;
    fetch(getInternalAllLineSegmentsDetailsUrl() + "/" + jobId, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authToken}`
      },
    })
      .then((response) => response.json())
      .then((result) => {
        console.log('Success:', result);
        dispatch(jobSummaryDetailsAction({ type: 'SET_JOB_ALL_LINESEGMENTS', payload: result }));
      })
      .catch((error) => {
        console.error('Error:', error);
        dispatch(jobSummaryDetailsAction({ type: 'SET_JOB_ALL_LINESEGMENTS', payload: {} }));
      });

  } catch (error) {
    dispatch(jobSummaryDetailsAction({ type: 'SET_JOB_ALL_LINESEGMENTS', payload: {} }));
  }
};

export const postJobRouteUpdate = async (jobId: any, jobOptionId: any, jobRoute: any, 
  allJobs: any, dispatch: any) => {
  try {
    let authToken = localStorage.getItem("Authorization");
    fetch(getInternalUpdateLineRouteUrl(), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authToken}`
      },
      body: JSON.stringify({jobId, jobOptionId, jobRoute, allJobs})
    })
      .then((response) => response.json())
      .then((result) => {
        console.log('Success:', result);
        
      })
      .catch((error) => {
        console.error('Error:', error);
        
      });

  } catch (error) {
    
  }
};

