import React from "react";
import { convertPointTo4326, formatAmount, formatWithIntl, isEmptyObject, reverseGeometryCoordinates } from "../../../helpers/Utils.js";
import JobSummaryOverviewMap from "./JobSummaryOverviewMap.tsx";
import JobOptionDetailsMaps from "./JobOptionDetailsMap.tsx";

export default function JobOptionDetail({ displayData, searchOptionDetails, jobRouteEdited, routeUpdateCallBack }) {


    function getSearchRecord(searchOptionList, id) {
        if (searchOptionList == null || searchOptionList == undefined) return null;
        let retVal = searchOptionList.filter((item) => item.jobOptionId === id);
        if (retVal.length === 0) return null;
        return retVal[0];
    }

    return (
        <>

            <div className="flex justify-center bg-gray-100">
                {displayData != null && !isEmptyObject(displayData) &&
                    <div className="w-full  bg-white shadow-lg rounded-lg p-3 flex">
                        <div className="w-full" >
                            <div className="grid grid-cols-2 gap-8">
                                {/* First Column */}
                                <div className="pr-6">
                                    <div className='flex text-sm leading-none justify-between'>
                                        <p className="p-2 font-medium" style={{ color: "#4b5563" }}>GSP :</p><span className="py-2" style={{ color: "#334155" }}>
                                            {getSearchRecord(searchOptionDetails, displayData?.id)?.gsp
                                                && getSearchRecord(searchOptionDetails, displayData?.id)?.gsp
                                                + "-" + getSearchRecord(searchOptionDetails, displayData?.id)?.gspName}</span>
                                    </div>
                                    <div className='flex text-sm leading-none justify-between'>
                                        <p className="p-2 font-medium" style={{ color: "#4b5563" }}>BSP :</p><span className="py-2" style={{ color: "#334155" }}>
                                            {getSearchRecord(searchOptionDetails, displayData?.id)?.bsp
                                                && getSearchRecord(searchOptionDetails, displayData?.id)?.bsp
                                                + "-" + getSearchRecord(searchOptionDetails, displayData?.id)?.bspName}
                                        </span>
                                    </div>
                                    <div className='flex text-sm leading-none justify-between'>
                                        <p className="p-2 font-medium" style={{ color: "#4b5563" }}>Primary :</p><span className="py-2" style={{ color: "#334155" }}>
                                            {getSearchRecord(searchOptionDetails, displayData?.id)?.primary
                                                && getSearchRecord(searchOptionDetails, displayData?.id)?.primary
                                                + "-" + getSearchRecord(searchOptionDetails, displayData?.id)?.primaryName
                                                + "(" + getSearchRecord(searchOptionDetails, displayData?.id)?.feederId + ")"}
                                        </span>
                                    </div>
                                    <div className='flex text-sm leading-none justify-between'>
                                        <p className="p-2 font-medium" style={{ color: "#4b5563" }}>Min Headroom: </p><span className="py-2" style={{ color: "#334155" }}>
                                            {displayData?.minHeadroom && parseFloat(displayData?.minHeadroom).toFixed(0) + "A (M), "}
                                            {displayData?.minBackfeederHeadroom && parseFloat(displayData?.minBackfeederHeadroom).toFixed(0) + "A (BF)"}</span>
                                    </div>
                                    <div className='flex text-sm leading-none justify-between'>
                                        <p className="p-2 font-medium" style={{ color: "#4b5563" }}>Max Voltage Drop:</p><span className="py-2" style={{ color: "#334155" }}>
                                            {displayData?.maxVoltageDrop && (parseFloat(displayData?.maxVoltageDrop) * 100).toFixed(1) + "% (M), "}
                                            {displayData?.maxBackfeederVoltageDrop && (parseFloat(displayData?.maxBackfeederVoltageDrop) * 100).toFixed(1) + "% (BF)"}</span>
                                    </div>


                                </div>
                                {/* Second Column */}
                                <div className="pr-6">
                                    <div className='flex text-sm leading-none justify-between'>
                                        <p className="p-2 font-medium" style={{ color: "#4b5563" }}>Backfeeder1 :</p><span className="py-2" style={{ color: "#334155" }}>
                                            {getSearchRecord(searchOptionDetails, displayData?.id)?.backfeeder1Sub
                                                && getSearchRecord(searchOptionDetails, displayData?.id)?.backfeeder1Sub
                                                + "-" + getSearchRecord(searchOptionDetails, displayData?.id)?.backfeeder1SubName
                                                + "(" + getSearchRecord(searchOptionDetails, displayData?.id)?.backfeeder1FeederId + ")"}
                                        </span>
                                    </div>
                                    {getSearchRecord(searchOptionDetails, displayData?.id)?.backfeeder2Sub && <div className='flex text-sm leading-none justify-between'>
                                        <p className="p-2 font-medium" style={{ color: "#4b5563" }}>Backfeeder2 :</p><span className="py-2" style={{ color: "#334155" }}>

                                            {getSearchRecord(searchOptionDetails, displayData?.id)?.backfeeder2Sub
                                                + "-" + getSearchRecord(searchOptionDetails, displayData?.id)?.backfeeder2SubName
                                                + "(" + getSearchRecord(searchOptionDetails, displayData?.id)?.backfeeder2FeederId + ")"}
                                        </span>
                                    </div>}
                                    {jobRouteEdited && jobRouteEdited === true &&
                                        <button
                                            onClick={(e) => routeUpdateCallBack()}
                                            className="px-2 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 text-xs"
                                        >
                                            Recalculate
                                        </button>
                                    }


                                </div>


                            </div>
                        </div>
                        {/* <div className="w-1/5 h-full">
                            <JobSummaryOverviewMap derivedArea={displayData.derivedArea?.coordinates} jobCoordinate={displayData.derivedPoint?.coordinates}
                                jobId={displayData.jobId}
                            />
                        </div> */}
                    </div>
                }
            </div >

        </>
    );

}