import { useDispatch } from "react-redux";
import { formatAmount, formatWithIntlNoTime, getArrayFromMedian, isEmptyObject } from "../../../helpers/Utils.js";
import { updateJobOptionIdInJobSummaryPage, updateJobOptionSearchField } from "../../../State_Management/Redux/actions.ts";
import { useState } from "react";

const JobOptionCostTable = ({ displayData }) => {

    const dispatch = useDispatch();

    const [selectedType, setSelectedType] = useState('all');

    const [tooltip, setTooltip] = useState({ show: false, content: "", x: 0, y: 0 });

    const handleMouseEnter = (e, content) => {
        const rect = e.target.getBoundingClientRect();
        setTooltip({
            show: true,
            content,
            x: rect.x + rect.width / 2,
            y: rect.y - 10,
        });
    };

    const handleMouseLeave = () => {
        setTooltip({ show: false, content: "", x: 0, y: 0 });
    };

    const handleSelect = (value) => {
        setSelectedType(value);
    }

    function createToolTipText(inputMap) {
        let finalString = "";
        if (inputMap == null || inputMap == undefined) return finalString;
        inputMap.map((item) => {
            finalString += item.key + ": " + item.value + "<br>";
        });
        return finalString;
    }

    function getCostTable() {
        if (displayData == null || displayData == undefined) return null;
        let lineChanges = [];
        lineChanges = displayData.lineChanges?.filter((item) =>
            (item.contestableYN === 1 && selectedType === "contestable") ||
            (item.contestableYN !== 1 && selectedType === "non-contestable") ||
            (selectedType == "all"));
        let subChanges = [];
        subChanges = displayData.substationChanges?.filter((item) =>
            (item.contestable === 1 && selectedType === "contestable") ||
            (item.contestable !== 1 && selectedType === "non-contestable") ||
            (selectedType == "all"));

        let backfeederChanges = [];
        if (displayData.backfeederResults !== null && displayData.backfeederResults !== undefined &&
            displayData.backfeederResults.length > 0) {

            backfeederChanges = displayData.backfeederResults[0]?.backfeederChanges?.filter((item) =>
                (item.contestableYN === 1 && selectedType === "contestable") ||
                (item.contestableYN !== 1 && selectedType === "non-contestable") ||
                (selectedType == "all"));
        }
        let summaryChanges = [];
        let totalCost = 0.0;
        if (!(lineChanges == undefined || lineChanges == null || lineChanges.length === 0)) {
            for (let item of lineChanges) {
                let row = {};
                row.type = "Line (M)";
                row.old = item.cableBefore;
                row.new = item.cableAfter;
                row.length = item.length;
                row.costType = item.contestableYN === 1 ? "Contestable" : "Non-Contestable";
                row.cost = item.upgradeCost;
                row.id = item.lineId;
                row.oldId = item.oldLineId;
                row.isNewitem = item.oldLineId == null ? 1 : 0;
                row.isFake = item.fakeYN;
                summaryChanges.push(row);
                totalCost += item.upgradeCost;
                let toolTipMap = [];
                if (row.isNewitem && item.oldLineId) {
                    toolTipMap.push({ key: "Old Line Id", value: item.oldLineId });
                }
                toolTipMap.push({ key: "Length", value: item.length });
                row.tooltipText = createToolTipText(toolTipMap);

            }
        }
        if (!(subChanges == undefined || subChanges == null || subChanges.length === 0)) {
            for (let item of subChanges) {
                let row = {};
                row.type = "Substation";
                row.old = item.capacityBefore ? item.capacityBefore + " kVA" : "" ;
                row.new = item.capacityAfter ? item.capacityAfter + " kVA": "" ;
                row.length = null;
                row.costType = item.contestable === 1 ? "Contestable" : "Non-Contestable";
                row.cost = item.upgradeCost;
                row.id = item.substationId;
                row.oldId = item.substationId;
                row.isNewitem = item.substationId == null ? 1 : 0;
                row.isFake = item.substationId == null ? 1 : 0;
                summaryChanges.push(row);
                totalCost += item.upgradeCost;
                row.toolTipText = "";
            }
        }

        if (!(backfeederChanges == undefined || backfeederChanges == null || backfeederChanges.length === 0)) {
            for (let item of backfeederChanges) {
                let row = {};
                row.type = "Line (BF)";
                row.old = item.cableBefore;
                row.new = item.cableAfter;
                row.length = item.length;
                row.costType = item.contestableYN === 1 ? "Contestable" : "Non-Contestable";
                row.cost = item.upgradeCost;
                row.id = item.lineId;
                row.oldId = item.lineId;
                row.isNewitem = 0;
                row.isFake = 0;
                summaryChanges.push(row);
                totalCost += item.upgradeCost;
                let toolTipMap = [];
                toolTipMap.push({ key: "Length", value: item.length });
                row.tooltipText = createToolTipText(toolTipMap);
            }
        }

        return { totalCost: totalCost, items: summaryChanges }

    }


    return (
        <>
            {displayData !== null && displayData !== undefined && !isEmptyObject(displayData) &&
                <div className="flex flex-col items-center justify-between gap-4 md:flex-row w-1/2">
                    <div className="block w-full  ">
                        <nav>
                            <ul role="tablist" className="relative flex flex-row p-1 rounded-lg bg-blue-gray-50 bg-opacity-60">
                                <li role="tab"
                                    className="relative flex items-center justify-center w-full h-full px-2 py-1 font-sans text-xs antialiased font-normal leading-relaxed text-center bg-transparent cursor-pointer select-none text-blue-gray-900"
                                    data-value="all" onClick={(e) => handleSelect("all")}>
                                    <div className="z-20 text-xs">
                                        &nbsp;&nbsp;All&nbsp;&nbsp;
                                    </div>
                                    {selectedType === "all" && <div className="absolute inset-0 z-10 h-full bg-white rounded-md shadow"></div>}
                                </li>
                                <li role="tab"
                                    className="relative flex items-center justify-center w-full h-full px-2 py-1 font-sans text-xs antialiased font-normal leading-relaxed text-center bg-transparent cursor-pointer select-none text-blue-gray-900"
                                    data-value="non-contestable" onClick={(e) => handleSelect("non-contestable")}>
                                    <div className="z-20 text-xs">
                                        &nbsp;&nbsp;Non-Contestable&nbsp;&nbsp;
                                    </div>
                                    {selectedType === "non-contestable" && <div className="absolute inset-0 z-10 h-full bg-white rounded-md shadow"></div>}
                                </li>
                                <li role="tab"
                                    className="relative flex items-center justify-center w-full h-full px-2 py-1 font-sans text-xs antialiased font-normal leading-relaxed text-center bg-transparent cursor-pointer select-none text-blue-gray-900"
                                    data-value="contestable" onClick={(e) => handleSelect("contestable")} >
                                    <div className="z-20 text-xs">
                                        &nbsp;&nbsp;Contestable&nbsp;&nbsp;
                                    </div>
                                    {selectedType === "contestable" && <div className="absolute inset-0 z-10 h-full bg-white rounded-md shadow"></div>}
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            }
            {displayData && getCostTable()?.items && !isEmptyObject(displayData) &&
                <div className="overflow-y-auto">

                    < table className="w-full  text-sm text-center rtl:text-right text-gray-500 dark:text-gray-400 overflow-y-auto">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 sticky top-0 z-10">
                            <tr>
                                <th scope="col" className="px-6 py-3">Item </th>
                                {/* <th scope="col" className="px-6 py-3">BSP</th> */}
                                <th scope="col" className="px-6 py-3">Type</th>
                                <th scope="col" className="px-6 py-3">Old</th>
                                <th scope="col" className="px-6 py-3">New</th>
                                <th scope="col" className="px-6 py-3 normal-case">LENGTH (m)</th>
                                <th scope="col" className="px-6 py-3">Price Type</th>
                                {/* <th scope="col" className="px-6 py-3">Backfeeder1</th>
                                <th scope="col" className="px-6 py-3">Backfeeder2</th> */}
                                {/* <th scope="col" className="px-6 py-3">Non Contestable cost</th>
                                <th scope="col" className="px-6 py-3">Contestable Cost</th> */}
                                <th scope="col" className="px-6 py-3">Cost</th>
                                {/* <th scope="col" className="px-6 py-3"></th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {getCostTable()?.items.map(item => (
                                <tr
                                    // key={jobItem.jobOptionId} 
                                    className={`bg-white text-gray-600 hover:bg-gray-50 hover:text-gray-600 dark:hover:bg-gray-600 border-b dark:bg-gray-800 dark:border-gray-700  cursor-pointer`}
                                    onMouseEnter={(e) => handleMouseEnter(e, item.tooltipText)}
                                    onMouseLeave={handleMouseLeave}
                                >
                                    <td className="px-6 py-4"
                                    >
                                        {item.isFake === 1 ? "New" : item.id}</td>

                                    {/* <td className="px-6 py-4">{jobItem.bsp && jobItem.bsp + "-"}{jobItem.bspName}</td> */}
                                    <td className="px-6 py-4">{item.type}</td>
                                    <td className="px-6 py-4">{item.old}</td>
                                    <td className="px-6 py-4">{item.new}</td>
                                    <td className="px-6 py-4">{item.length && new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(item.length)}</td>
                                    <td className="px-6 py-4">{item.costType}</td>
                                    {/* <td className="px-6 py-4">{jobItem.backfeeder1Sub && jobItem.backfeeder1Sub + "-"}{jobItem.backfeeder1SubName} {jobItem.backfeeder1FeederId && "(" + jobItem.backfeeder1FeederId + ")"}</td>
                                    <td className="px-6 py-4">{jobItem.backfeeder2Sub && jobItem.backfeeder2Sub + "-"}{jobItem.backfeeder2SubName} {jobItem.backfeeder2FeederId && "(" + jobItem.backfeeder2FeederId + ")"}</td> */}
                                    {/* <td className="px-6 py-4">{jobItem.nonContestableCost && formatAmount('£', jobItem.nonContestableCost)}</td>
                                    <td className="px-6 py-4">{jobItem.contestableCost && formatAmount('£', jobItem.contestableCost)}</td> */}
                                    <td className="px-6 py-4">{item.cost && formatAmount('£', item.cost)}</td>
                                    {/* <td className="px-6 py-4">
                                        <div onClick={(e) => handleSelect(jobItem.jobOptionId)}
                                            className="font-medium text-blue-600 dark:text-blue-500 hover:underline cursor-pointer"
                                        >
                                            Edit
                                        </div>
                                    </td> */}
                                </tr>
                            ))}
                        </tbody>
                        {getCostTable()?.items && getCostTable()?.items?.length > 0 &&
                            <tfoot className="text-sm text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 sticky top-0 z-10">
                                <tr>
                                    <th scope="col" className="px-6 py-3">Total </th>
                                    {/* <th scope="col" className="px-6 py-3">BSP</th> */}
                                    <th scope="col" className="px-6 py-3"></th>
                                    <th scope="col" className="px-6 py-3"></th>
                                    <th scope="col" className="px-6 py-3"></th>
                                    <th scope="col" className="px-6 py-3"></th>
                                    <th scope="col" className="px-6 py-3"></th>
                                    {/* <th scope="col" className="px-6 py-3">Backfeeder1</th>
                                <th scope="col" className="px-6 py-3">Backfeeder2</th> */}
                                    {/* <th scope="col" className="px-6 py-3">Non Contestable cost</th>
                                <th scope="col" className="px-6 py-3">Contestable Cost</th> */}
                                    <th scope="col" className="px-6 py-3">{getCostTable()?.totalCost && formatAmount('£', getCostTable()?.totalCost)}</th>
                                    {/* <th scope="col" className="px-6 py-3"></th> */}
                                </tr>
                            </tfoot>}
                    </table>




                </div>
            }

        </>
    );

}

export default JobOptionCostTable;