import React from "react";
import { convertPointTo4326, reverseGeometryCoordinates } from "../../../helpers/Utils.js";
import JobSummaryOverviewMap from "./JobSummaryOverviewMap.tsx";
import JobOptionDetailsMaps from "./JobOptionDetailsMap.tsx";

export default function JobOptionsSummaryDetail({ displayData , lineSegment, allLineSegments, jobOptionId, jobSummaryDetails }) {

    const [jobPoint, setJobPoint] = React.useState(undefined);
    React.useEffect(() => {
        var coordinatePoint = reverseGeometryCoordinates(displayData?.connectionPoint);
        if (coordinatePoint == null || coordinatePoint == undefined || coordinatePoint.length == 0) {
            setJobPoint(undefined);
        }
        else {
            setJobPoint(coordinatePoint);
        }
    }, [displayData]);


    return (
        <>

            <div className=" w-full h-full border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                <div className=" w-full h-full bg-white shadow-lg rounded-lg p-3">

                    <div className="w-full h-screen  overflow-hidden">
                        <JobOptionDetailsMaps derivedArea={displayData.derivedArea?.coordinates} connectionPoint={displayData?.connectionPointIn4326?.coordinates}
                           lineRoute={displayData?.lineRoute} lineSegment={lineSegment?.geometryIn4326} alllineSegments = {allLineSegments} lineSegmentId={displayData?.linesegmentId}
                           jobOptionId = {jobOptionId} jobSummaryDetails ={jobSummaryDetails}
                        />
                    </div>
                </div>
            </div>

        </>
    );

}