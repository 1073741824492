import React, { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

const OAuthFailure = () => {
    const navigate = useNavigate();
   
    
    useEffect(() => {        
        
            navigate('/login?loginfailure=true');
        
    }, []);

    return <h1>Processing OAuth login...</h1>;
};

export default OAuthFailure;
