import { useDispatch } from "react-redux";
import { formatAmount, formatWithIntlNoTime, getArrayFromMedian } from "../../../helpers/Utils";
import { updateJobOptionIdInJobSummaryPage, updateJobOptionSearchField } from "../../../State_Management/Redux/actions.ts";
import { useState } from "react";

const JobOptionsSummaryTable = ({ displayData }) => {

    const dispatch = useDispatch();

    const [selectedRow, setSelectedRow] = useState(null);

    const [pageSelected, setPageSelected] = useState(0);

    const handleChange = (value) => {
        if (value === pageSelected) return;
        updateJobOptionSearchField('page', value, dispatch);
        setPageSelected(value);
    };

    const handleSelect = (value) => {
        if (value !== selectedRow) {
            updateJobOptionIdInJobSummaryPage(value, dispatch);
            setSelectedRow(value);
        }
        else{
            updateJobOptionIdInJobSummaryPage(null, dispatch);
            setSelectedRow(null);
        }
    }


    return (
        <>

            {displayData && displayData.content &&
                <>

                    < table className="w-full  text-sm text-center rtl:text-right text-gray-500 dark:text-gray-400  p-2">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 sticky top-0 z-0">
                            <tr>
                                <th scope="col" className="px-6 py-3">GSP </th>
                                {/* <th scope="col" className="px-6 py-3">BSP</th> */}
                                <th scope="col" className="px-6 py-3">Primary</th>
                                <th scope="col" className="px-6 py-3">Secondary</th>
                                <th scope="col" className="px-6 py-3">LineSegment</th>
                                {/* <th scope="col" className="px-6 py-3">Backfeeder1</th>
                                <th scope="col" className="px-6 py-3">Backfeeder2</th> */}
                                <th scope="col" className="px-6 py-3">Non Contestable cost</th>
                                {/*<th scope="col" className="px-6 py-3">Contestable Cost</th> */}
                                <th scope="col" className="px-6 py-3">Total Cost</th>
                                {/* <th scope="col" className="px-6 py-3"></th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {displayData && displayData.content && displayData.content.map(jobItem => (
                                <tr
                                    key={jobItem.jobOptionId} onClick={(e) => handleSelect(jobItem.jobOptionId)}
                                    className={`${selectedRow === jobItem.jobOptionId ? 'bg-blue-500 text-white' : jobItem.feasible === 0 ? 'bg-red-200 text-white' : 'bg-white text-gray-600 hover:bg-gray-50 hover:text-gray-600 dark:hover:bg-gray-600'} border-b dark:bg-gray-800 dark:border-gray-700  cursor-pointer`}
                                >
                                    <td className="px-6 py-4">{jobItem.gsp && jobItem.gsp + "-"}{jobItem.gspName}</td>

                                    {/* <td className="px-6 py-4">{jobItem.bsp && jobItem.bsp + "-"}{jobItem.bspName}</td> */}
                                    <td className="px-6 py-4">{jobItem.primary && jobItem.primary + "-"}{jobItem.primaryName} {jobItem.feederId && "(" + jobItem.feederId + ")"}</td>
                                    <td className="px-6 py-4">{jobItem.secondary && jobItem.secondary + "-"}{jobItem.secondarySubstationName}</td>
                                    <td className="px-6 py-4">{jobItem.linesegmentId}</td>
                                    {/* <td className="px-6 py-4">{jobItem.backfeeder1Sub && jobItem.backfeeder1Sub + "-"}{jobItem.backfeeder1SubName} {jobItem.backfeeder1FeederId && "(" + jobItem.backfeeder1FeederId + ")"}</td>
                                    <td className="px-6 py-4">{jobItem.backfeeder2Sub && jobItem.backfeeder2Sub + "-"}{jobItem.backfeeder2SubName} {jobItem.backfeeder2FeederId && "(" + jobItem.backfeeder2FeederId + ")"}</td> */}
                                    <td className="px-6 py-4">{jobItem.nonContestableCost && formatAmount('£', jobItem.nonContestableCost)}</td>
                                    {/*<td className="px-6 py-4">{jobItem.contestableCost && formatAmount('£', jobItem.contestableCost)}</td> */}
                                    <td className="px-6 py-4">{jobItem.totalCost && formatAmount('£', jobItem.totalCost)}</td>
                                    {/* <td className="px-6 py-4">
                                        <div onClick={(e) => handleSelect(jobItem.jobOptionId)}
                                            className="font-medium text-blue-600 dark:text-blue-500 hover:underline cursor-pointer"
                                        >
                                            Edit
                                        </div>
                                    </td> */}
                                </tr>
                            ))}
                        </tbody>
                    </table>


                    <nav
                        className="flex items-center flex-column flex-wrap md:flex-row justify-between pt-4"
                        aria-label="Table navigation"
                    >
                        <span className="text-sm font-normal text-gray-500 dark:text-gray-400 mb-4 md:mb-0 block w-full md:inline md:w-auto">
                            Showing <span className="font-semibold text-gray-900 dark:text-white">{Math.min(displayData.pageable.offset + 1, displayData.totalElements)} - {Math.min(displayData.pageable.offset + displayData.pageable.pageSize, displayData.totalElements)}</span> of{" "}
                            <span className="font-semibold text-gray-900 dark:text-white">{displayData.totalElements}</span>
                        </span>
                        <ul className="inline-flex -space-x-px rtl:space-x-reverse text-sm h-8">
                            {displayData.pageable.pageNumber > 0 &&
                                <li>
                                    <div
                                        onClick={(e) => handleChange(displayData.pageable.pageNumber - 1)}
                                        className="flex items-center justify-center cursor-pointer px-3 h-8 ms-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                                    >
                                        Previous
                                    </div>
                                </li>
                            }
                            {displayData.totalPages > 1 && getArrayFromMedian(displayData.pageable.pageNumber + 1, 5, displayData.totalPages).map((page) => (
                                <li key={page}>
                                    <div
                                        onClick={(e) => handleChange(page - 1)}
                                        className={`flex items-center justify-center px-3 h-8 leading-tight cursor-pointer ${page === displayData.pageable.pageNumber + 1
                                            ? "text-blue-600 border border-gray-300 bg-blue-50 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white "
                                            : "text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                                            }`}
                                    >
                                        {page}
                                    </div>
                                </li>
                            ))}
                            {displayData.pageable.pageNumber + 1 < displayData.totalPages &&
                                <li>
                                    <div
                                        onClick={(e) => handleChange(displayData.pageable.pageNumber + 1)}
                                        className="flex items-center justify-center px-3 h-8 cursor-pointer leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                                    >
                                        Next
                                    </div>
                                </li>
                            }
                        </ul>
                    </nav>

                </>
            }


        </>
    );

}

export default JobOptionsSummaryTable;