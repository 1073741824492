import React from "react";
import { PRICE_TYPE_MAP, QUOTE_TYPE_BUDGET, QUOTE_TYPE_SYSTEM_GENERATED } from "../../../constants/appConstants";
import { formatAmount, formatWithIntl, isEmptyObject } from "../../../helpers/Utils";
import MapWithDraw from "../../Client/Map_Pointer.tsx";
import JobSummaryOverviewMap from "./JobSummaryOverviewMap.tsx";


export default function JobSummaryOverview({ displayData }) {

    return (
        <>
            <div className="flex justify-center bg-gray-100">
                {displayData != null && !isEmptyObject(displayData) &&
                    <div className="w-full  bg-white shadow-lg rounded-lg p-3 flex">
                        <div className="w-full" >
                            <div className="grid grid-cols-3 gap-8">
                                {/* First Column */}
                                <div className="pr-6">
                                    <div className='flex text-sm leading-none justify-between'>
                                        <p className="p-3 font-medium" style={{ color: "#4b5563" }}>Job ID :</p><span className="py-3" style={{ color: "#334155" }}>{displayData?.jobId}</span>
                                    </div>
                                    <div className='flex text-sm leading-none justify-between'>
                                        <p className="p-3 font-medium" style={{ color: "#4b5563" }}>Connection Type :</p><span className="py-3" style={{ color: "#334155" }}>{displayData?.connectionType}</span>
                                    </div>
                                    <div className='flex text-sm leading-none justify-between'>
                                        <p className="p-3 font-medium" style={{ color: "#4b5563" }}>Submitted on :</p><span className="py-3 text-right" style={{ color: "#334155" }}>{formatWithIntl(displayData?.submittedDate)}</span>
                                    </div>
                                    <div className='flex text-sm leading-none justify-between'>
                                        <p className="p-3 font-medium" style={{ color: "#4b5563" }}>Quote Type:</p><span className="py-3" style={{ color: "#334155" }}>{displayData?.quoteType}</span>
                                    </div>
                                    <div className='flex text-sm leading-none justify-between'>
                                        <p className="p-3 font-medium" style={{ color: "#4b5563" }}>Price Type:</p><span className="py-3" style={{ color: "#334155" }}>{displayData?.priceType}</span>
                                    </div>

                                </div>
                                {/* Second Column */}
                                <div className="pr-6">
                                    <div className='flex text-sm leading-none justify-between'>
                                        <p className="p-3 font-medium" style={{ color: "#4b5563" }}>Job Capacity :</p><span className="py-3" style={{ color: "#334155" }}>{displayData != null && !isEmptyObject(displayData) && <p> {displayData?.jobCapacity} kVA</p>} </span>
                                    </div>
                                    <div className='flex text-sm leading-none justify-between'>
                                        <p className="p-3 font-medium" style={{ color: "#4b5563" }}>Preferred Voltage :</p><span className="py-3" style={{ color: "#334155" }}>{displayData?.preferredConnectionVoltage}</span>
                                    </div>

                                    <div className='flex text-sm leading-none justify-between'>
                                        <p className="p-3 font-medium" style={{ color: "#4b5563" }}>SLA Date :</p><span className="py-3 text-right" style={{ color: "#334155" }}>{displayData?.etaCompletionDate}</span>
                                    </div>

                                    <div className='flex text-sm leading-none justify-between'>
                                        <p className="p-3 font-medium" style={{ color: "#4b5563" }}>Job Status :</p><span className="py-3" style={{ color: "#334155" }}>{displayData?.jobStatus}</span>
                                    </div>

                                    <div className='flex text-sm leading-none justify-between'>
                                        <p className="p-3 font-medium" style={{ color: "#4b5563" }}>Postcode :</p><span className="py-3" style={{ color: "#334155" }}>{displayData?.postcode}</span>
                                    </div>

                                </div>

                                {/* Third Column */}
                                <div className="pr-6">
                                    <div className='flex text-sm leading-none justify-between'>
                                        <p className="p-2 font-medium" style={{ color: "#4b5563" }}>Planner :</p><span className="py-2" style={{ color: "#334155" }}>{displayData != null && !isEmptyObject(displayData) && <p> {displayData?.plannerLastName + ", "} {displayData?.plannerFirstName}</p>} </span>
                                    </div>
                                    <div className='flex text-sm leading-none justify-between'>
                                        <p className="p-2 font-medium" style={{ color: "#4b5563" }}>Quote Fees:</p><span className="py-2" style={{ color: "#334155" }}>{formatAmount(displayData?.currencySymbol, displayData?.quoteFeesAmount)}</span>
                                    </div>
                                    <div className='flex text-sm leading-none justify-between'>
                                        <p className="p-2 font-medium" style={{ color: "#4b5563" }}>Paid on :</p><span className="py-2" style={{ color: "#334155" }}>{formatWithIntl(displayData?.quoteFeesPaymentDate)}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="w-1/5 h-full">
                            <JobSummaryOverviewMap derivedArea={displayData.derivedArea?.coordinates} jobCoordinate={displayData.derivedPoint?.coordinates}
                                jobId={displayData.jobId}
                            />
                        </div> */}
                    </div>
                }
            </div >
        </>
    );

}