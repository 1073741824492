import React, { useEffect, useRef, useState } from "react";
import { MapContainer, TileLayer, Marker, Popup, FeatureGroup } from "react-leaflet";
import L, { circle, circleMarker, LatLngBounds, LatLngExpression, rectangle } from "leaflet";
import { EditControl } from "react-leaflet-draw";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import "leaflet-draw";
import "leaflet-editable"
import { removeDuplicatesInArray, reverseGeometryCoordinates, reverseLineStringCoordinates, reverseMultiLineStringCoordinates } from "../../../helpers/Utils";
import { updateJobOptionSearchField, updateJobRouteEditedInJobSummaryPage, updateJobRouteLatLngsEditedInJobSummaryPage } from "../../../State_Management/Redux/actions.ts";
import { useDispatch } from "react-redux";

// Custom icon for the point
const createSvgIcon = () =>
    new L.DivIcon({
        className: "custom-svg-icon custommarkerinMap", // Custom class for styling if needed
        html: `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" class="size-4">
    <path fill-rule="evenodd" d="M3.75 2a.75.75 0 0 0 0 1.5H4v9h-.25a.75.75 0 0 0 0 1.5H6a.5.5 0 0 0 .5-.5v-3A.5.5 0 0 1 7 10h2a.5.5 0 0 1 .5.5v3a.5.5 0 0 0 .5.5h2.25a.75.75 0 0 0 0-1.5H12v-9h.25a.75.75 0 0 0 0-1.5h-8.5ZM6.5 4a.5.5 0 0 0-.5.5V5a.5.5 0 0 0 .5.5H7a.5.5 0 0 0 .5-.5v-.5A.5.5 0 0 0 7 4h-.5ZM6 7a.5.5 0 0 1 .5-.5H7a.5.5 0 0 1 .5.5v.5A.5.5 0 0 1 7 8h-.5a.5.5 0 0 1-.5-.5V7Zm3-3a.5.5 0 0 0-.5.5V5a.5.5 0 0 0 .5.5h.5A.5.5 0 0 0 10 5v-.5a.5.5 0 0 0-.5-.5H9Zm-.5 3a.5.5 0 0 1 .5-.5h.5a.5.5 0 0 1 .5.5v.5a.5.5 0 0 1-.5.5H9a.5.5 0 0 1-.5-.5V7Z" clip-rule="evenodd" />
  </svg>  
    `, // SVG markup for the icon
        iconSize: [16, 16], // Size of the icon
        iconAnchor: [8, 8], // Anchor the icon (bottom-center point)
    });

const markerSvgIcon = () =>
    new L.DivIcon({
        className: "custom-svg-icon custommarkerinMap", // Custom class for styling if needed
        html: `
<svg width="24px" height="24px" viewBox="-4 0 36 36" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools --> <title>map-marker</title> <desc>Created with Sketch.</desc> <defs> </defs> <g id="Vivid.JS" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"> <g id="Vivid-Icons" transform="translate(-125.000000, -643.000000)"> <g id="Icons" transform="translate(37.000000, 169.000000)"> <g id="map-marker" transform="translate(78.000000, 468.000000)"> <g transform="translate(10.000000, 6.000000)"> <path d="M14,0 C21.732,0 28,5.641 28,12.6 C28,23.963 14,36 14,36 C14,36 0,24.064 0,12.6 C0,5.641 6.268,0 14,0 Z" id="Shape" fill="#FF6E6E"> </path> <circle id="Oval" fill="#0C0058" fill-rule="nonzero" cx="14" cy="14" r="7"> </circle> </g> </g> </g> </g> </g> </g></svg>
    `, // SVG markup for the icon
        iconSize: [24, 24], // Size of the icon
        iconAnchor: [12, 12],
    })

const JobOptionDetailsMaps = ({ connectionPoint, derivedArea, lineRoute, lineSegment, alllineSegments, lineSegmentId, jobOptionId, jobSummaryDetails }) => {
    const mapRef = useRef<L.Map | null>(null);
    const [drawnLayer, setDrawnLayer] = useState<L.Layer | null>(null);
    const [point, setPoint] = useState<LatLngExpression | null>(null);
    const [pointInside, setPointInside] = useState<boolean | null>(null);
    const [drawControl, setDrawControl] = useState(null);
    const [hashMap, setHashMap] = useState(null);
    const [currentLineSegment, setCurrentLineSegment] = useState(null);
    const [drawnItems, setDrawnItems] = useState<L.Layer | null>(null);
    const [lineSegmentClickListeners, setLineSegmentClickListeners] = useState([]);
    const [jobCoordinateLayer, setJobCoordinateLayer] = useState<L.Layer | null>(null);
    const dispatch = useDispatch();



    function getToolTipText(lineSegment) {
        let returnString = "Id: " + lineSegment.id + "<br>"
            + "Voltage: " + lineSegment.lineVoltage + "<br>"
            + "Material: " + lineSegment.lineMaterial + "<br>"
            + "Source Substation: " + lineSegment.srcSubstation
            + " (" + lineSegment.substationCircuit + ")<br>"
            + ((lineSegment.tempRecordYN == "Y") ? "WIP (Job: " + lineSegment.jobId + ")" : "");
        return returnString;
    }

    function lineSegmentClicked(id) {
        updateJobOptionSearchField('lineSegmentId', "" + id, dispatch);
        updateJobOptionSearchField('page', "0", dispatch);
    }


    function enableToolTip(polyline, item) {
        lineSegmentClickListeners.map((listener) => {
            if (listener.id === item.id) {
                polyline.on('mouseover', listener.mouseOver);

                polyline.on('mouseout', listener.mouseOut);

                polyline.on('click', listener.mouseClick);
            }
        });

    }

    function disableToolTip(polyline, item) {
        lineSegmentClickListeners.map((listener) => {
            if (listener.id === item.id) {
                polyline.off('mouseover', listener.mouseOver);

                polyline.off('mouseout', listener.mouseOut);

                polyline.off('click', listener.mouseClick);
            }
        });
    }

    useEffect(() => {
        const map = mapRef.current?.target;
        if (!map) return;
        let drawLayer;
        if (!drawnItems) {
            drawLayer = new L.FeatureGroup();
            setDrawnItems(drawLayer);
            map.addLayer(drawLayer);
            console.log("FeatureGroup added");
        }

        if (!drawControl) {
            let _drawControl = new L.Control.Draw({
                edit: {
                    featureGroup: drawLayer, // Enable editing on this FeatureGroup
                    remove: true,                // Allow removing layers
                },
                draw: {
                    polyline: {
                        shapeOptions: {
                            color: 'red',
                            weight: 4,
                            opacity: 1
                        }
                    }, polygon: false, rectangle: false,
                    circle: false, marker: false, circlemarker: false
                },
            });
            setDrawControl(_drawControl);
            // Add the Draw Control to the map
            map.addControl(_drawControl);
            console.log("Control added")
        }

    }, []);

    useEffect(() => {
        const map = mapRef.current?.target;
        if (!map) return;
        let hashMap1 = new Map();
        if (alllineSegments == null || alllineSegments == undefined || alllineSegments.length === 0) {
            if (hashMap != null) {
                hashMap.forEach((value, key) => {
                    map.removeLayer(value);
                })
            }
            setHashMap(null);
            return;
        }
        let eventHandlers = [];
        for (let item of alllineSegments) {
            let geometry;
            console.log(item);
            geometry = reverseGeometryCoordinates(item.geometryIn4326);
            const polyline = L.polyline(geometry, { color: 'blue', opacity: 0.5 });
            polyline.key = "Line:" + item.id;
            polyline.keyText = item.id;
            polyline.bindTooltip(getToolTipText(item), { permanent: false, direction: 'left', sticky: true });
            const mouseOver = (e) => { e.target.openTooltip(); };
            polyline.on("mouseover", mouseOver);
            const mouseOut = (e) => { e.target.closeTooltip(); };
            polyline.on("mouseout", mouseOut);
            const mouseClick = (e) => {
                console.log('Id: ' + e.target.keyText);
                lineSegmentClicked(e.target.keyText);
            };
            polyline.on("click", mouseClick);
            eventHandlers.push({
                id: item.id, mouseOver, mouseOut, mouseClick
            });
            setLineSegmentClickListeners(eventHandlers);

            map.addLayer(polyline);
            console.log("added linesegment polyline");
            hashMap1.set("Line:" + item.id, polyline);
        }
        setHashMap(hashMap1);





    }, [alllineSegments]);

    useEffect(() => {
        const map = mapRef.current?.target;
        if (!map) return;
        if (alllineSegments == null || alllineSegments == undefined) return;

        map.eachLayer(function (layer: any) {
            if (!(layer instanceof L.TileLayer)) {

                if (layer instanceof L.FeatureGroup) {

                }
                else {
                    if ((layer instanceof L.Polyline) && hashMap.has(layer.key)) {
                        map.removeLayer(layer);
                        console.log("polyline to be deleted");
                    }
                    else if ((layer instanceof L.Marker) && jobCoordinateLayer !== layer) {
                        map.removeLayer(layer);
                    }
                }
            }
        });




        if (connectionPoint != null && connectionPoint != undefined) {
            let bounds;
            console.log("connection Point: ")
            console.log(connectionPoint);
            setPoint(new L.LatLng(connectionPoint[1], connectionPoint[0]))
            L.marker(new L.LatLng(connectionPoint[1], connectionPoint[0]), { icon: createSvgIcon() }).addTo(map);
            if (bounds) bounds.extend(new L.LatLng(connectionPoint[1], connectionPoint[0]))
            else bounds = L.latLngBounds(new L.LatLng(connectionPoint[1], connectionPoint[0]), new L.LatLng(connectionPoint[1], connectionPoint[0]));
            map.fitBounds(bounds);
            setPoint(new L.LatLng(connectionPoint[1], connectionPoint[0]))
        }



        for (let item of alllineSegments) {
            let polyline = hashMap.get("Line:" + item.id);
            if (polyline) {
                if ((jobOptionId == null || currentLineSegment == null || currentLineSegment == undefined)) {
                    polyline.setStyle({ color: 'blue', opacity: 0.5 });
                    map.addLayer(polyline);
                    enableToolTip(polyline, item);
                }
                else if (item.id === currentLineSegment) {
                    polyline.setStyle({ color: 'green', opacity: 1 });
                    map.addLayer(polyline);
                    enableToolTip(polyline, item);
                }
                else if (item.id !== currentLineSegment) {
                    // polyline.setStyle({ color: 'transparent', opacity: 0 });
                    // disableToolTip(polyline, item);
                }
            }
        }

    }, [currentLineSegment, jobOptionId, connectionPoint]);

    useEffect(() => {
        let bounds;
        const map = mapRef.current?.target;
        if (!map) return;




        map.eachLayer(function (layer: any) {
            if (!(layer instanceof L.TileLayer)) {

                if (layer instanceof L.FeatureGroup) {
                    layer.eachLayer(childlayer => {
                        if (childlayer instanceof L.Polyline)
                            console.log("featuregroup polyline to be deleted");
                        layer.removeLayer(childlayer);
                        console.log("child layer deleted");
                        // if (layer instanceof L.Control.Layers)
                        //     console.log("L Control deleted")
                    })
                    // map.removeLayer(layer);;
                    // console.log("FeatureGroup deleted");
                }
                else {
                    if ((layer instanceof L.Polyline) && !hashMap.has(layer.key)) {
                        map.removeLayer(layer);
                        console.log("polyline to be deleted");
                    }

                }
                if (layer instanceof L.Control.Layers) {
                    map.remove(layer);
                    console.log("Control deleted")
                }

            }
        });

        if (jobSummaryDetails?.derivedPoint != null && jobSummaryDetails?.derivedPoint != undefined) {

            let jobLocation = jobSummaryDetails?.derivedPoint?.coordinates;
            if (jobLocation) {

                let jobMarker = L.marker(new L.LatLng(jobLocation[1], jobLocation[0]), { icon: markerSvgIcon() }).addTo(map);
                setJobCoordinateLayer(jobMarker);
                bounds = L.latLngBounds(new L.LatLng(jobLocation[1], jobLocation[0]), new L.LatLng(jobLocation[1], jobLocation[0]));
            }
        }

        //map.fitBounds([[0, 0], [0, 0]]);





        if (lineSegment) {
            let geometry;
            //geometry = reverseGeometryCoordinates(lineSegment);
            //L.polyline(geometry, { color: 'blue' }).addTo(map);
            // if (bounds) bounds.extend(L.polyline(geometry).getBounds());
            if (currentLineSegment) {
                let polyline = hashMap.get("Line:" + currentLineSegment);
                if (polyline) {
                    polyline.setStyle({ color: 'blue', opacity: 0.5 })
                }
            }
            if (lineSegmentId != null && lineSegmentId != undefined) {
                let polyline = hashMap.get("Line:" + lineSegmentId);
                if (polyline) {
                    polyline.setStyle({ color: 'green', opacity: 1 })
                }
                setCurrentLineSegment(lineSegmentId);
                if (bounds) bounds.extend(polyline.getBounds());
            }

        }

        if (lineRoute) {
            let geometry;
            console.log(lineRoute);
            geometry = reverseGeometryCoordinates(lineRoute);
            console.log(geometry);
            geometry = geometry.reduce((acc, val) => acc.concat(val), []);
            geometry = geometry.filter((coord, index, arr) => {
                return index === 0 || JSON.stringify(coord) !== JSON.stringify(arr[index - 1])
            });
            geometry = removeDuplicatesInArray(geometry);
            console.log(geometry);
            const polyline = L.polyline(geometry, { color: 'red' });
            console.log(polyline.getLatLngs());
            polyline.on('click', (e) => {
                //alert('Polyline clicked!');
                console.log('Clicked polyline coordinates:');
                console.log(e);
            });
            drawnItems.addLayer(polyline);
            console.log("added featuregroup polyline");
            if (bounds) bounds.extend(L.polyline(geometry).getBounds());
        }

        map.on('draw:editstart', () => {
            // Enable vertex removal
            drawnItems?.eachLayer((layer) => {
                if (layer instanceof L.Polyline) {
                    const editHandler = layer.editing;
                    editHandler.enable();
                    editHandler.options.vertexRemove = true; // Enable vertex removal
                }
            });
        });

        // Handle the editing of layers
        map.on('draw:edited', (e) => {
            const layers = e.layers; // Edited layers
            layers.eachLayer((layer) => {
                // Update the polyline in the FeatureGroup
                const latlngs = layer.toGeoJSON();
                latlngs.crs = {
                    type: "name",
                    properties: {
                        name: "EPSG:4326"
                    }
                };
                console.log('Edited polyline coordinates:', latlngs);
                updateJobRouteEditedInJobSummaryPage(true, dispatch);
                updateJobRouteLatLngsEditedInJobSummaryPage(latlngs, dispatch);
            });

        });

        map.on(L.Draw.Event.CREATED, function (event) {
            const layer = event.layer;

            let layerFound = 0;
            drawnItems?.eachLayer((existingLayer) => {
                if (existingLayer instanceof L.Polyline) {
                    map.removeLayer(layer);
                    drawnItems.removeLayer(layer);
                    layerFound = 1;
                    return;
                }
            })
            if (layerFound === 0) {
                drawnItems?.addLayer(layer); // Add the drawn layer to the map
                updateJobRouteEditedInJobSummaryPage(true, dispatch);
                const latlngs = layer.toGeoJSON();
                latlngs.crs = {
                    type: "name",
                    properties: {
                        name: "EPSG:4326"
                    }
                };
                updateJobRouteLatLngsEditedInJobSummaryPage(latlngs, dispatch);
            }

        });




        let shapeLayer;
        // if (derivedArea) {
        //     var latLngsPolygon = [];
        //     console.log(derivedArea);
        //     for (let coord of derivedArea[0]) {
        //         latLngsPolygon.push([coord[1], coord[0]]);
        //     }
        //     const isRectangle = detectRectangle(latLngsPolygon);

        //     if (isRectangle) {
        //         // If it's a rectangle, draw it as a rectangle
        //         const bounds: L.LatLngBoundsExpression = latLngsPolygon as L.LatLngBoundsExpression;
        //         shapeLayer = L.rectangle(bounds, {
        //             color: 'green',
        //             weight: 2,
        //             fillOpacity: 0.3,
        //         }).addTo(map);
        //     } else {
        //         // If it's not a rectangle, draw it as a polygon
        //         shapeLayer = L.polygon(latLngsPolygon, {
        //             color: 'blue',
        //             fillColor: '#3388ff',
        //             fillOpacity: 0.5,
        //         }).addTo(map);
        //     }

        //     //L.marker(lineSegment).addTo(map);
        //     // L.marker(lineRoute).addTo(map);
        //     //L.marker(connectionPoint).addTo(map);

        //     setDrawnLayer(shapeLayer);
        //     if (bounds) bounds.extend(shapeLayer.getBounds());
        // }

        if (bounds) map.fitBounds(bounds);
        let loop = 1;
        map.eachLayer((layer) => {
            if (layer instanceof L.FeatureGroup) {
                console.log("featuregroup found");
                layer.eachLayer((childlayer) => {
                    if (childlayer instanceof L.Polyline) {
                        console.log("featuregroup polyline found ")
                        console.log(childlayer);
                    }
                })
            }
            console.log("loop " + loop++);
            if (layer instanceof L.Polyline) {
                console.log("polyline found ");
                console.log(layer);
            }
        })

    }, [lineSegment, lineRoute, derivedArea, jobSummaryDetails])
    // Function to detect if the LineString forms a rectangle
    const detectRectangle = (coords: LatLngExpression[]): boolean => {
        if (coords.length === 4 || coords.length === 5) {
            const [p1, p2, p3, p4] = coords;
            const isRectangular =
                p1[0] === p2[0] && p3[0] === p4[0] && p1[1] === p4[1] && p2[1] === p3[1];
            return isRectangular;
        }
        return false
    }

    // useEffect(() => {
    //   const map = mapRef.current?.target as unknown;
    //   if (!map) return;

    //   // Feature group to store editable layers

    //   const drawnItems = new L.FeatureGroup();
    //   (map as any).addLayer(drawnItems);

    //   // Initialize draw control and pass featureGroup
    //   const drawControl = new L.Control.Draw({
    //     draw: {
    //       polyline: false,
    //       marker: false,
    //       rectangle: true,
    //       polygon: true,
    //       circle: true,
    //     },
    //     edit: {
    //       featureGroup: drawnItems,
    //     },
    //   });
    //   map?.addControl(drawControl);

    //   // Handle when a shape is drawn
    //   map.on(L.Draw.Event.CREATED, (event: any) => {
    //     const layer = event.layer;
    //     drawnItems.clearLayers(); // Remove previous layers
    //     drawnItems.addLayer(layer); // Add new drawn layer
    //     setDrawnLayer(layer); // Save the drawn layer
    //     setPoint(null); // Clear any previous marker
    //     setPointInside(null); // Reset the point inside state
    //   });

    //   // Add a marker only when the user clicks inside the drawn shape
    //   map.on("click", (e: L.LeafletMouseEvent) => {
    //     const latlng = e.latlng;
    //     if (drawnLayer && isPointInsideShape(latlng)) {
    //       setPoint(latlng); // Set the marker point on the map only if inside
    //       setPointInside(true); // Confirm point is inside
    //     } else {
    //       setPoint(null); // Clear the point if it's outside the shape
    //       setPointInside(false); // Show feedback that the point is outside
    //     }
    //   });

    //   return () => {
    //     map.removeControl(drawControl); // Cleanup control
    //   };
    // }, [drawnLayer]);

    // Function to check if the point is inside the drawn shape
    const isPointInsideShape = (latlng: LatLngExpression) => {
        if (!drawnLayer) return false;

        if (drawnLayer instanceof L.Circle) {
            const radius = drawnLayer.getRadius();
            const center = drawnLayer.getLatLng();
            const distance = mapRef.current?.distance(center, latlng);
            return Boolean(distance && distance <= radius); // Point is inside if distance is <= radius
        } else if (drawnLayer instanceof L.Polygon) {
            return drawnLayer.getBounds().contains(latlng); // For polygon, check if point is in bounds
        }
        return false;
    };

    return (
        <>
            <MapContainer
                center={[51.50, -0.09]}
                zoom={50}
                // editable={true}
                className="w-full h-full"
                whenReady={(mapInstance: any) => (mapRef.current = mapInstance)}

            >
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />

                {/* Render a marker if the point is inside the drawn shape */}
                {point && (
                    <Marker position={point} icon={createSvgIcon()}>
                        <Popup>
                            {pointInside !== null &&
                                (pointInside
                                    ? "This point is inside the drawn shape."
                                    : "This point is outside the drawn shape.")}
                        </Popup>
                    </Marker>
                )}

                {/* <FeatureGroup ref={mapRef}>
                    
                    <EditControl
                        position="topright"
                       // onEdited={handleEdited} // Callback when editing is completed
                        draw={{
                            polyline: false, // Disable new polyline creation
                            polygon: false,
                            rectangle: false,
                            circle: false,
                            marker: false,
                        }}
                        edit={{
                            remove: false, // Disable layer removal
                        }}
                    />
                </FeatureGroup> */}
            </MapContainer>


            {/* Feedback to the user if the point is outside */}
            {/*  <div className="feedback">
        {pointInside === false && (
          <p className="error">You can only place the point inside the drawn area.</p>
        )}
      </div>

      <style jsx>{`
        .feedback {
          margin-top: 10px;
        }
        .error {
          color: red;
          font-weight: bold;
        }
      `}</style>
      <div>points:{String(point)}</div> */}

        </>
    );
};

export default JobOptionDetailsMaps;
