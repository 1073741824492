// counterSlice.ts
import { createSlice } from '@reduxjs/toolkit';



const initialState: any = {
  searchCriteria: {
    postcode: null,
    connType: null,
    quoteType: [],
    gsp: null,
    jobStatus: [],
    sort: 'id,desc',
    page: 0,
    size: 10,
  },
  searchResults: [],
  // jobCardList: {

  // }
};



export const jobListSummaryReducer = createSlice({
  name: 'jobListSummaryReducer',
  initialState,
  reducers: {
    jobListSummaryAction: (state:any, payloadAction: any) => {
      switch (payloadAction.payload.type) {
        case 'UPDATE_SEARCH_FIELD':
          return {
            ...state, searchCriteria: {
              ...state.searchCriteria,
              [payloadAction.payload.payload.field]: payloadAction.payload.payload.value
            }
          };
        case 'SET_SEARCH_RESULTS':
          return {
            ...state, searchResults: payloadAction.payload.payload
          };
        default:
          return state;
      }
      // jobCardSelected: (state, data: any) => {
      //   state.jobCardList = data.payload;
      // } 
    }
  },
});

export const { jobListSummaryAction, } = jobListSummaryReducer.actions;
export default jobListSummaryReducer.reducer;
