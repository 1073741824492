// counterSlice.ts
import { createSlice } from '@reduxjs/toolkit';



const initialState: any = {
  jobId: null,
  jobOptionSearchCriteria: {
    lineSegmentId: null,
    gsp: null,
    bsp: null,
    primary: null,
    feederId: null,
    sort: null,
    page: 0,
    size: 10,
    jobId: null,
  },
  jobOptionsSearchResults: [],
  jobSummarySection: {
    derivedPoint: null,
    postcode: null,
    jobId: null,
    jobStatus: null,
    connectionType: null,
    quoteType: null,
    priceType: null,
    submittedDate: null,
    plannerId: null,
    plannerFirstName: null,
    plannerLastName: null,
    preferredConnectionVoltage: null,
    jobCapacity: null
  },
  jobOptionId: null,
  jobOptionDetailSection: {},
  jobRouteEdited: false,
  jobRouteLatLngsEdited: null,
  jobCoordinateLatLngsEdited: null,
  lineSegmentDetails: {},
  jobOptionLineSegment: null,
  jobAllLineSegments: null,
};



export const jobSummaryDetailsReducer = createSlice({
  name: 'jobSummaryDetailsReducer',
  initialState,
  reducers: {
    jobSummaryDetailsAction: (state, payloadAction: any) => {
      switch (payloadAction.payload.type) {
        case 'UPDATE_JOBOPTION_SEARCH_FIELD':
          return {
            ...state, jobOptionSearchCriteria: {
              ...state.jobOptionSearchCriteria,
              [payloadAction.payload.payload.field]: payloadAction.payload.payload.value
            }
          };
        case 'SET_JOB_OPTION_SEARCH_RESULTS':
          return {
            ...state, jobOptionsSearchResults: payloadAction.payload.payload
          };
        case 'SET_JOB_ID':
          return {
            ...initialState, jobId: payloadAction.payload.payload, jobOptionSearchCriteria: {
              ...initialState.jobOptionSearchCriteria,
              jobId: payloadAction.payload.payload
            }
          };
        case 'SET_JOB_SUMMARY_SECTION':
          return {
            ...state, jobSummarySection: payloadAction.payload.payload
          };
        case 'SET_JOB_OPTION_ID':
          return {
            ...state, jobOptionId: payloadAction.payload.payload
          };
        case 'SET_JOB_OPTION_DETAILS':
          return {
            ...state, jobOptionDetailSection: payloadAction.payload.payload
          };
        case 'SET_LINESEGMENT':
          return {
            ...state, jobOptionLineSegment: payloadAction.payload.payload
          };
        case 'SET_LINESEGMENT_DETAILS':
          return {
            ...state, lineSegmentDetails: payloadAction.payload.payload
          };
        case 'SET_JOB_ROUTE_EDITED':
          return {
            ...state, jobRouteEdited: payloadAction.payload.payload
          };
        case 'SET_JOB_ROUTE_LATLNGS_EDITED':
          return {
            ...state, jobRouteLatLngsEdited: payloadAction.payload.payload
          };
        case 'SET_JOB_COORDINATES_LATLNG_EDITED':
          return {
            ...state, jobCoordinateLatLngsEdited: payloadAction.payload.payload
          };
        case 'SET_JOB_ALL_LINESEGMENTS':
          return {
            ...state, jobAllLineSegments: payloadAction.payload.payload
          };
        default:
          return state;
      }

    }
  },
});

export const { jobSummaryDetailsAction, } = jobSummaryDetailsReducer.actions;
export default jobSummaryDetailsReducer.reducer;
