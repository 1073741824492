// store.ts
import { configureStore } from '@reduxjs/toolkit';
import itemsReducer from './JobDatareducers.ts';
import SelfUserDatareducers from './SelfUserDatareducers.ts';
import jobListSummary from './jobListSummary.ts';
import jobListSearchCriteriaReducer from './jobListSearchCriteriaReducer.ts';
import jobSummaryDetailsReducer from './jobSummaryDetailsReducer.ts';


export const store = configureStore({
  reducer: {
    jobData: itemsReducer,
    selfUserData: SelfUserDatareducers,
    jobSearchCriteria: jobListSearchCriteriaReducer,
    jobListSummary: jobListSummary,   
    jobSummaryDetailsReducer: jobSummaryDetailsReducer,
  },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
