//require("dotenv").config();
const mainBackendUrl = `${process.env.REACT_APP_BACKEND_API_PROTOCOL}://${process.env.REACT_APP_BACKEND_API_URL}`
const internalBackendUrl = `${process.env.REACT_APP_INTERNAL_BACKEND_API_PROTOCOL}://${process.env.REACT_APP_INTERNAL_BACKEND_API_URL}`

const getAllJobsUrl = () => {
    return `${mainBackendUrl}/${process.env.REACT_APP_BACKEND_API_GET_ALL_JOBS}`
}

const getJobDetailsUrl = (jobId) => {
    return `${mainBackendUrl}/${process.env.REACT_APP_BACKEND_API_GET_JOB_DETAILS}/${jobId}`
}

const getGoogleOauthUrl = () => {
    return `${mainBackendUrl}/${process.env.REACT_APP_BACKEND_API_OAUTH2_GOOGLE_AUTHORIZATION}`
}

const getJobSubmitUrl = () => {
    return `${mainBackendUrl}/${process.env.REACT_APP_BACKEND_API_POST_NEW_JOB}`
}

const getAzureADOauthUrl = () => {
    return `${internalBackendUrl}/${process.env.REACT_APP_INTERNAL_BACKEND_API_OAUTH2_AZURE_AUTHORIZATION}`
}

const getSelfInternalUserDetailUrl = () => {
    return `${internalBackendUrl}/${process.env.REACT_APP_INTERNAL_BACKEND_API_SELF_USER_DETAILS}`
}

const getInternalJobStatusesDetailsUrl = () => {
    return `${internalBackendUrl}/${process.env.REACT_APP_INTERNAL_BACKEND_API_JOB_STATUSES}`
}

const getInternalJobListSummaryTableUrl= () => {
    return `${internalBackendUrl}/${process.env.REACT_APP_INTERNAL_BACKEND_API_JOB_SUMMARY_TABLE}`;
}

const getInternalJobSummaryDetailsUrl= () => {
    return `${internalBackendUrl}/${process.env.REACT_APP_INTERNAL_BACKEND_API_JOB_SUMMARY_DETAILS}`;
}

const getInternalJobSummaryOptionsTableUrl= () => {
    return `${internalBackendUrl}/${process.env.REACT_APP_INTERNAL_BACKEND_API_JOB_SUMMARY_OPTIONS_TABLE}`;
}

const getInternalJobSummaryOptionDetailsUrl= () => {
    return `${internalBackendUrl}/${process.env.REACT_APP_INTERNAL_BACKEND_API_JOB_SUMMARY_OPTIONS_DETAILS}`;
}

const getInternalLineDetailsUrl = () => {
    return `${internalBackendUrl}/${process.env.REACT_APP_INTERNAL_BACKEND_API_LINESEGMENT_DETAILS}`;
}

const getInternalAllLineSegmentsDetailsUrl = () => {
    return `${internalBackendUrl}/${process.env.REACT_APP_INTERNAL_BACKEND_API_ALL_LINESEGMENTS}`;
}

const getInternalUpdateLineRouteUrl = () => {
    return `${internalBackendUrl}/${process.env.REACT_APP_INTERNAL_BACKEND_API_UPDATE_LINE_ROUTE}`;
}



module.exports = {
    getAllJobsUrl, getGoogleOauthUrl, getJobDetailsUrl, getJobSubmitUrl, 
    getAzureADOauthUrl, getSelfInternalUserDetailUrl,
    getInternalJobStatusesDetailsUrl, getInternalJobListSummaryTableUrl,
    getInternalJobSummaryDetailsUrl, getInternalJobSummaryOptionsTableUrl,
     getInternalJobSummaryOptionDetailsUrl, getInternalLineDetailsUrl,
     getInternalAllLineSegmentsDetailsUrl, getInternalUpdateLineRouteUrl,
}   


