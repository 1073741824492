// // counterSlice.ts
// import { createSlice } from '@reduxjs/toolkit';



// const initialState: any = {
//   jobListSearchCriteria:{
//     postcode: null,
//     connType: null,
//     quoteType: [],
//     gsp: null,
//     jobStatus: [],
//     sort: 'id,desc',
//     page: 0,
//     size: 10,
//   },
//   // jobCardList: {

//   // }
// };



// export const jobListSearchCriteria = createSlice({
//   name: 'jobListSearchCriteria',
//   initialState,
//   reducers: {
//     jobListCriteriaSelected: (state,payloadAction:any) => {
//       state.jobList=payloadAction.payload;
//     },
//     // jobCardSelected: (state, data: any) => {
//     //   state.jobCardList = data.payload;
//     // } 
//   },
// });

// export const { jobListCriteriaSelected, } = jobListSearchCriteria.actions;
// export default jobListSearchCriteria.reducer;
