import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline'
import * as React from 'react';
import MapWithDraw from './Map_Pointer.tsx';
import JobCards from './JobCards.tsx';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { fetchData, postData } from '../../../Services/apiServices.ts';
import { getAllJobsUrl, getInternalJobListSummaryTableUrl } from '../../../helpers/EnvironmentHelper.js';
import JobDetailsCard from './JobDetailsCard.tsx';
import { useSearchParams } from 'react-router-dom';
import Toast from '../../Toast.tsx';
import JobSummaryOverview from './JobSummaryOverview.tsx';
import JobOptionsSummaryTable from './JobOptionsSummaryTable.tsx';
import JobOptionsSummaryDetail from './JobOptionsSummaryDetail.tsx';
import { fetchJobAllLineSegmentDetails, fetchJobOptionDetails, fetchJobOptionSearchResults, fetchJobSummaryDetailResults, fetchLineSegmentDetails, postJobRouteUpdate, updateJobIdInJobSummaryPage } from '../../../State_Management/Redux/actions.ts';
import JobOptionDetail from './JobOptionDetail.tsx';
import JobOptionCostTable from './JobOptionCostTable.tsx';

const navigation = [
  { name: 'Dashboard', href: '#', current: true },
  { name: 'Team', href: '#', current: false },
  { name: 'Projects', href: '#', current: false },
  { name: 'Calendar', href: '#', current: false },
]

export default function JobSummaryPage() {

  const jobSummaryDetailsData = useSelector((state: any) => state.jobSummaryDetailsReducer.jobSummarySection);
  const jobSummaryOptionsData = useSelector((state: any) => state.jobSummaryDetailsReducer.jobOptionsSearchResults);
  const jobSummaryOptionSearchCriteria = useSelector((state: any) => state.jobSummaryDetailsReducer.jobOptionSearchCriteria);
  const jobOptionId = useSelector((state: any) => state.jobSummaryDetailsReducer.jobOptionId);
  const jobOptionsDetailsData = useSelector((state: any) => state.jobSummaryDetailsReducer.jobOptionDetailSection);
  const jobId = useSelector((state: any) => state.jobSummaryDetailsReducer.jobId);
  const lineSegmentDetails = useSelector((state: any) => state.jobSummaryDetailsReducer.lineSegmentDetails);
  const lineSegment = useSelector((state: any) => state.jobSummaryDetailsReducer.jobOptionLineSegment);
  const alllineSegments = useSelector((state: any) => state.jobSummaryDetailsReducer.jobAllLineSegments);
  const jobRouteEdited = useSelector((state: any) => state.jobSummaryDetailsReducer.jobRouteEdited);
  const jobRouteLatLngEdited = useSelector((state: any) => state.jobSummaryDetailsReducer.jobRouteLatLngsEdited);
  const [params] = useSearchParams();

  const dispatch = useDispatch();

  const updateJobRoute = () => {
    postJobRouteUpdate(jobId, jobOptionId, jobRouteLatLngEdited, true, dispatch);
  }

  React.useEffect(() => {
    const value = params.get('jobId');
    updateJobIdInJobSummaryPage(value, dispatch);
    // fetchJobSummaryDetailResults(jobId, dispatch);
    // fetchJobOptionSearchResults(jobSummaryOptionSearchCriteria, dispatch);
  }, []);

  React.useEffect(() => {
    console.log(jobId);
    fetchJobSummaryDetailResults(jobId, dispatch);
    fetchJobOptionSearchResults(jobSummaryOptionSearchCriteria, dispatch);
    fetchJobAllLineSegmentDetails(jobId, dispatch);
  }, [jobId, dispatch]);

  React.useEffect(() => {
    console.log(jobOptionId);
    fetchJobOptionDetails(jobOptionId, dispatch);
  }, [jobOptionId, dispatch]);

  React.useEffect(() => {
    console.log(lineSegment);
    fetchLineSegmentDetails(lineSegment, dispatch);
  }, [lineSegment, dispatch]);

  React.useEffect(() => {
    //fetchJobSummaryDetailResults(jobId, dispatch);
    fetchJobOptionSearchResults(jobSummaryOptionSearchCriteria, dispatch);
  }, [jobSummaryOptionSearchCriteria, dispatch]);

  return (
    <>
      <div className="flex flex-row overflow-hidden w-full h-full ">
        <div className="w-1/2 overflow-hidden p-2" >
          <div className=" h-1/4 p-2 ">
            <JobSummaryOverview displayData={jobSummaryDetailsData} />
          </div>

          <div className="  h-1/4 overflow-y-scroll p-2">
            <JobOptionsSummaryTable displayData={jobSummaryOptionsData} />
          </div>

          <div className=" h-1/4 p-2 ">
            <JobOptionDetail displayData={jobOptionsDetailsData} 
            searchOptionDetails = {jobSummaryOptionsData?.content}
            jobRouteEdited = {jobRouteEdited} 
            routeUpdateCallBack = {updateJobRoute}/>
          </div>

          <div className=" h-1/4  p-2 ">
            <JobOptionCostTable displayData={jobOptionsDetailsData}/>
          </div>

        </div>
        <div className="w-1/2 h-full " >

          <JobOptionsSummaryDetail displayData={jobOptionsDetailsData} lineSegment={lineSegmentDetails}
            allLineSegments={alllineSegments} jobOptionId={jobOptionId} jobSummaryDetails={jobSummaryDetailsData} />

        </div>

      </div>
    </>
  )
}
